<template>
  <v-container fluid>
    <v-row class=" p-y-6 mb-5">
      <v-col lg="3" sm="12">
        <BackButton :handler="gotoSalesTeam"/>
      </v-col>
      <v-col lg="6" md="6" sm="12">
        <div class="d-flex justify-center align-center gap-x-6">
          <div style="max-width: 16rem;max-height: 10rem">
            <view-image :image="salesTeamInfo.image_path ? salesTeamInfo.image_path : ''"  :width="165" :height="165" class="rounded-lg" defaultImage="user"/>
          </div>
          <div class="mt-2">
            <h3 class="text-xl m-b-4">
              {{ salesTeamInfo.first_name }} {{ salesTeamInfo.last_name || "" }}
            </h3>
            <p style="line-height: 1px">{{ salesTeamInfo.title }}</p>
            <div class="d-block d-lg-flex gap-x-8">
              <SvgIcon class="gap-x-4 m-b-4 font-medium">
                <template v-slot:icon>
                  <SMSIcon/>
                </template>
                {{ salesTeamInfo.email }}
              </SvgIcon>
              <SvgIcon class="gap-x-4 m-b-4 font-medium">
                <template v-slot:icon>
                  <CallIcon/>
                </template>
                {{ salesTeamInfo.mobile }}
              </SvgIcon>
            </div>
            <div class="d-flex gap-x-8 ">
              <div class="stats_data" v-if="checkReadPermission($modules.salesTeam.revenue.slug)">
                <p class="text-dark-gray text-base font-normal">Sales</p>
                <p  class="text-blue font-semibold">{{ Number(salesTeamInfo.total_sales) | toCurrency }}</p>
              </div>
              <div class="stats_data" v-if="checkReadPermission($modules.salesTeam.commission.slug)">
                <p class="text-dark-gray text-base font-normal">Revenue</p>
                <p  class="text-blue font-semibold">{{ Number(salesTeamInfo.total_commission) | toCurrency }}</p>
              </div>
            </div>
          </div>
        </div>

      </v-col>
      <v-col lg="3" sm="12" class="mt-sm-3  d-sm-flex">
        <v-btn
            class="ma-2 text_capitalize "
            @click="
            confirmDelete({
              id: salesTeamId,
              status_id: salesTeamInfo.status_id,
              flag: true,
            })
          "
            outlined
            :color="salesTeamInfo.status_id == 1?'red':''"
        >
          <span class="ml-1">{{ salesTeamInfo.status_id == 1 ? "Deactivate" : "Activate" }}</span>
        </v-btn>
        <v-btn
            style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46 "
            class="ma-2  text_capitalize "
            outlined

            @click="editSalesTeam(salesTeamId)"
        > <EditBtnIcon/>
          <span class="ml-1">Edit</span>   </v-btn>
      </v-col>
    </v-row>

    <div class="row mt-4">
      <div class="col-12">
        <v-card class="shadow-0">
          <v-card-actions class="text-center" >
            <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                light
                slider-color="transparent"
                class="q-tabs-secondary shadow-0  border-bottom"
            >
              <v-tab href="#details" @click="showCalender = false"
                     active-class="active_tab_fill_icon"
              >
                <SvgIcon text="Logs">
                  <template v-slot:icon>
                    <SalesIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab
                  href="#sales"
                  @click="showCalender = false"
                  active-class="active_tab_stroke_icon"
                  v-if="checkWritePermission($modules.salesTeam.sales.slug)"
              >
                <SvgIcon text="Sales">
                  <template v-slot:icon>
                    <AnalyticsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
            </v-tabs>
          </v-card-actions>


          <v-card-text>
            <v-row no-gutters class="mt-6">
              <v-tabs-items v-model="tab">
                <v-tab-item value="details">
                  <sales-team-logs-section
                      :salesTeamId="salesTeamId"
                      :salesTeam="salesTeamInfo"
                      @reloadSalesTeam="salesTeamDetails(salesTeamId)"
                  />
                </v-tab-item>

                <v-tab-item value="sales">
                  <sales-team-sales-graph
                      :salesTeamId="salesTeamId"
                      :exportPermission="
              checkExportPermission($modules.salesTeam.management.slug)
            "
                  ></sales-team-sales-graph>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <sales-team-add-edit-modal :add-sales-team-dialog="addSalesTeamDialog"
                               :st-i-d="salesTeamId"
                               @cancelSalesTeam="cancelSalesTeam" v-if="addSalesTeamDialog" :roles="roles"  />
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import SalesTeamLogsSection from "./SalesTeamTabs/SalesTeamLogs";
import SalesTeamSalesGraph from "./SalesTeamTabs/SalesTeamSalesGraph";
import BackButton from "@/components/Common/BackButton.vue";
import SMSIcon from "@/assets/images/misc/sms.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import CallIcon from "@/assets/images/misc/call.svg";
import EditBtnIcon from "@/assets/images/misc/pencil-icon.svg";
import SalesIcon from "@/assets/images/sales_teams/people-icon.svg";
import AnalyticsIcon from '@/assets/images/sales_teams/sales.svg';
import SalesTeamAddEditModal from "@/views/SalesTeam/SalesTeamAddEditModal.vue";
export default {
  components: {
    SalesTeamAddEditModal,
    SalesIcon, EditBtnIcon, CallIcon, SvgIcon, SMSIcon,AnalyticsIcon, BackButton, SalesTeamLogsSection, SalesTeamSalesGraph },
  data() {
    return {
      tab: "details",
      salesTeamId: null,
      image: null,
      salesTeamInfo: {
        deleted_services: [],
      },



      valid: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      index: 0,
      params: { venue_service_ids: [] },
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      salesSalesTeamId: null,
      levels: [],
      pieReload: true,
      showCalender: false,
      addSalesTeamDialog:false,
      roles:[],
      // timeSlotList: [],
    };
  },
  created() {
    if (typeof this.$route.params.data != "undefined") {
      this.salesTeamId = parseInt(atob(this.$route.params.data));
      this.salesTeamDetails(this.salesTeamId, "view");
      // this.loadAvailability();
    }
  },
  watch: {
    tab: {
      immediate: true,
      handler(val) {
        if (val == "sales") {
          this.salesSalesTeamId = this.salesTeamId;
        } else {
          this.salesSalesTeamId = null;
        }
        if (val == "analysis") {
          this.pieReload = true;
        } else {
          this.pieReload = false;
        }
      },
    },
  },
  mounted() {
    this.getVenueRolesList();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  methods: {
    gotoSalesTeam() {
      this.$router.push({
        name: "SalesTeams",
      });
    },


    getVenueRolesList() {
      this.$http
          .get("venues/roles")
          .then((response) => {
            if (response.status == 200) this.roles = response.data.data;
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    cancelSalesTeam(){
      this.addSalesTeamDialog = false;
      this.salesTeamDetails(this.salesTeamId, "view");
    },


    salesTeamDetails(id) {
      if (!id) return;
      this.$http
        .get(`venues/sales-team/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.salesTeamInfo = response.data.data;
            this.salesTeamInfo.venue_service_ids = this.salesTeamInfo.sales_team_services.map(
              (item) => item.venue_service_id
            );
            this.salesTeamInfo.deleted_services = [];
            this.salesTeamInfo.status_id = this.salesTeamInfo.status_id.toString();
            this.salesTeamInfo.image_path = this.salesTeamInfo.image_path
              ? this.salesTeamInfo.image_path
              : null;
            this.editFlag = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },



    editSalesTeam(){
      this.addSalesTeamDialog = true;
    },

    confirmDelete(data) {
      console.log(this.salesTeamId);
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${
          data.status_id == 1 ? "delete" : "reactivate"
        } this sales person?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "sales_team",
        data: {
          flag: data.flag ? true : false,
        },
      };
    },
    confirmActions(data) {
      if (data.type == "sales_team") {
        this.toggleSalesTeamStatus(data.id);
      }
      this.confirmModel.id = null;
    },
    toggleSalesTeamStatus(id) {
      this.$http
        .put(`venues/sales-team/${id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.$router.push({
              name: "SalesTeams",
            });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.tab_active {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.flat-table {
  display: block;
  overflow: auto;
  width: auto;
  border-radius: 10px;
}
.flat-table th {
  background-color: rgb(4, 148, 184);
  color: white;
  font-weight: normal;
  padding: 8px 12px;
  text-align: center;
}
.flat-table td {
  background-color: #eeeeee;
  color: #6f6f6f;
  padding: 8px 12px;
}

.stats_data{
  p{
    margin: 0;
    padding: 0;
  }
}
</style>
