<template>
  <v-container fluid>
    <v-row>
      <sales-team-sales
        :params="params"
        :showProductType="true"
        @stackChange="stackChangeCapture"
        :stackType="stackType"
        :exportPermission="
          checkExportPermission($modules.salesTeam.dashboard.slug)
        "
      ></sales-team-sales>
    </v-row>
  </v-container>
</template>

<script>
import SalesTeamSales from "@/components/Chart/OrderSalesGraph";
export default {
  props: {
    salesTeamId: { type: Number, default: 0 },
  },
  components: {
    SalesTeamSales,
  },
  data() {
    return {
      btnShow: false,
      stackType: "all",
      params: {
        venue_service_ids: [],
        product_ids: [],
        product_type_ids: [],
        sales_team_ids: [this.salesTeamId],
      },
    };
  },
  methods: {
    stackChangeCapture(data) {
      this.stackType = data;
    },
  },
  mounted() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
};
</script>

<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
